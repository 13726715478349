<template>
  <div class="col s12 m6">
    <div>
      <div class="page-subtitle">
        <h4>{{$t('categoryPage.EditCategory')}}</h4>
      </div>
      <form @submit.prevent="editCategory">
        <div class="input-field" >
          <select ref="select" v-model="currentId">
            <option
              v-for="category of categories"
              :key="category.id"
              :value="category.id"
            >
              {{category.name}}
            </option>
          </select>
          <label>{{$t('categoryPage.SelectCategory')}}</label>
        </div>

        <div class="input-field">
          <input
            id="name"
            type="text"
            v-model="name"
            :class="{invalid: $v.name.$dirty && !$v.name.required}"
          >
          <label for="name">{{$t('categoryPage.CategoryName')}}</label>
          <span
            v-if="$v.name.$dirty && !$v.name.required"
            class="helper-text invalid"
          >
            {{$t('categoryPage.InputCategoryName')}}
          </span>
        </div>

        <div class="input-field">
          <input
            id="limit"
            type="number"
            v-model.number="limit"
            :class="{invalid: $v.limit.$dirty && !$v.limit.minValue}"
          >
          <label for="limit">{{$t('categoryPage.Limit')}}</label>
          <span
            v-if="$v.limit.$dirty && !$v.limit.minValue"
            class="helper-text invalid"
          >
            {{$t('categoryPage.MinValue')}} {{$v.limit.$params.minValue.min}}
          </span>
        </div>

        <button class="btn waves-effect waves-light" type="submit">
          {{$t('global.Update')}}
          <i class="material-icons right">send</i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'EditCategory',
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: '',
      limit: 100,
      select: null,
      currentId: null
    }
  },
  validations: {
    name: { required },
    limit: { minValue: minValue(100) }
  },
  watch: {
    currentId (catID) {
      const { name, limit } = this.categories.find(category => category.id === catID)
      this.name = name
      this.limit = limit
    }
  },
  methods: {
    async editCategory () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        const categoryData = {
          id: this.currentId,
          name: this.name,
          limit: this.limit
        }
        await this.$store.dispatch('editCategory', categoryData)
        this.$message('pluginMessages.CategoryIsUpdated')
        this.$emit('updateCategory', categoryData)
      } catch (e) {}
    }
  },
  created () {
    const { id, name, limit } = this.categories[0]
    this.currentId = id
    this.name = name
    this.limit = limit
  },
  mounted () {
    this.select = M.FormSelect.init(this.$refs.select)
    M.updateTextFields()
  },
  destroyed () {
    if (this.select && this.select.destroy) {
      this.select.destroy()
    }
  }
}
</script>

<style scoped>

</style>
