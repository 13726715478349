<template>
  <div class="col s12 m6">
    <div>
      <div class="page-subtitle">
        <h4>{{$t('categoryPage.NewCategory')}}</h4>
      </div>

      <form @submit.prevent="createCategory">
        <div class="input-field">
          <input
            id="name"
            type="text"
            v-model="name"
            :class="{invalid: $v.name.$dirty && !$v.name.required}"
          >
          <label for="name">{{$t('categoryPage.CategoryName')}}</label>
          <span
            v-if="$v.name.$dirty && !$v.name.required"
            class="helper-text invalid"
          >
            {{$t('categoryPage.InputCategoryName')}}
          </span>
        </div>

        <div class="input-field">
          <input
            id="limit"
            type="number"
            v-model.number="limit"
            :class="{invalid: $v.limit.$dirty && !$v.limit.minValue}"
          >
          <label for="limit">{{$t('categoryPage.Limit')}}</label>
          <span
            v-if="$v.limit.$dirty && !$v.limit.minValue"
            class="helper-text invalid"
          >
            {{$t('categoryPage.MinValue')}} {{$v.limit.$params.minValue.min}}
          </span>
        </div>

        <button class="btn waves-effect waves-light" type="submit">
          {{$t('global.Create')}}
          <i class="material-icons right">send</i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      name: '',
      limit: 100
    }
  },
  validations: {
    name: { required },
    limit: { minValue: minValue(100) }
  },
  mounted () {
    M.updateTextFields()
  },
  methods: {
    async createCategory () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        const category = await this.$store.dispatch('createCategory', {
          name: this.name,
          limit: this.limit
        })

        this.$message('pluginMessages.CategoryIsCreated', { categoryName: this.name })
        this.name = ''
        this.limit = 100
        this.$v.$reset()
        this.$emit('createCategory', category)
      } catch (e) {}
    }
  }
}
</script>
