<template>
  <div>
    <div class="page-title">
      <h3>{{$t('categoryPage.Category')}}</h3>
    </div>
    <section>
      <loader v-if="loading" />
      <div class="row" v-else>
        <CategoryCreate @createCategory="addNewCategory" />
        <CategoryEdit v-if="categories.length"
                      :categories="categories"
                      @updateCategory="updateCategories"
                      :key="categories.length + renderKey"
        />
        <p class="center" v-else>{{$t('categoryPage.CategoriesAbsent')}}</p>
      </div>
    </section>
  </div>
</template>

<script>
import CategoryCreate from '@/components/category/CategoryCreate'
import CategoryEdit from '@/components/category/CategoryEdit'

export default {
  name: 'categories',
  data () {
    return {
      categories: [],
      loading: true,
      renderKey: 0
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('menu.Categories')
    }
  },
  async mounted () {
    this.categories = await this.$store.dispatch('fetchCategories')
    this.loading = false
  },
  methods: {
    addNewCategory (category) {
      this.categories.push(category)
      console.log(this.categories)
    },
    updateCategories (category) {
      const idx = this.categories.findIndex(cat => cat.id === category.id)
      this.categories[idx].name = category.name
      this.categories[idx].limit = category.limit
      this.renderKey = Math.random()
    }
  },
  components: {
    CategoryCreate, CategoryEdit
  }
}
</script>
